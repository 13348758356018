import React from "react";

const Mission = () => {
  return (
    <section>
      <div className="grid grid-cols-1 lg:grid-cols-1 gap-5">
        <div className="w-full">
          <h2 className="font-bold p-3 text-center text-2xl uppercase bg-secondary text-white">
            Mission and vission
          </h2>
          <h2 className="font-semibold pb-3 pt-6">Mission Statement:</h2>
          <p className="text-textColor text-[14px]">
            Renix Unani Laboratories Ltd is dedicated to improving healthcare by
            producing high-quality complete medicines made with natural herbs.
            Our mission is to promote the benefits of the Unani treatment
            system, which has been proven to be effective and free from side
            effects. We strive to provide access to affordable, natural and safe
            healthcare solutions to communities in need.
          </p>
        </div>
        <div className="w-full">
          <h2 className="font-semibold pb-3">Vision Statement:</h2>
          <p className="text-textColor text-[14px]">
            Our vision is to become a leading pharmaceutical company in
            Bangladesh and beyond, providing holistic healthcare solutions that
            meet the evolving needs of our customers. We aim to be a trusted
            brand that delivers high-quality Unani medicines and services,
            enabling people to live healthier, happier lives.
          </p>
        </div>
        <div className="w-full">
          <h2 className="font-semibold pb-3">Strategies:</h2>
          <p className="text-textColor text-[14px]">
            {" "}
            <span className="font-semibold">
              Research and Development:
            </span>{" "}
            Renix Unani Laboratories Ltd. is committed to continuous research
            and development to enhance the quality and efficacy of our products.
            We invest in modernization of scientific methods to validate the
            benefits of natural herbs and ensure the safety of our medicines.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            <span className="font-semibold">Quality Assurance:</span> We have a
            dedicated team of quality assurance experts who ensure that our
            products meet the highest standards of quality, safety, and
            efficacy.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            <span className="font-semibold">
              Expansion and Diversification:
            </span>{" "}
            We are focused on expanding our reach and diversifying our product
            portfolio to meet the growing demand for natural healthcare
            solutions.
          </p>
        </div>
        <div className="w-full">
          <h2 className="font-semibold pb-3">Marketing Strategies:</h2>
          <p className="text-textColor text-[14px]">
            <span className="font-semibold">Digital Marketing: </span>We
            leverage digital marketing platforms to reach out to our target
            audience, creating brand awareness and promoting our products.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            <span className="font-semibold">
              Collaborations and Partnerships:
            </span>
            We collaborate with medical professionals and organizations to
            promote the benefits of Unani treatment and provide access to
            affordable healthcare solutions.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            <span className="font-semibold">Customer Service: </span>We
            prioritize customer satisfaction by providing prompt and effective
            customer service, building long-term relationships and loyalty.
          </p>
          &nbsp;
          <p className="text-textColor text-[14px]">
            <span className="font-semibold">Sponsorship and Events: </span>We
            sponsor healthcare-related events and participate in healthcare
            fairs to engage with our customers and promote our brand.
          </p>
        </div>

        <div className="w-full">
          <h2 className="font-semibold pb-3"> Renix Services: </h2>
          <p className="text-textColor text-[14px]">
            <span className="font-semibold">Services: </span>Renix Unani
            Laboratories Limited has introduced various services using modern
            technology to promote the Unani system of medicine both nationally
            and internationally. Notably, the Free Doctor's Help Line program
            through Video Calling and mobile phones aims to make the general
            public more reliant on Unani medicine, fostering a preference for
            safe medical care among the present and future generations of the
            country. By 2026, the company aims to establish medical and sales
            centers in every police station across the country, with the goal of
            providing high-quality international medicine to people from all
            walks of life. Inshallah (God willing).
          </p>
        </div>
      </div>
    </section>
  );
};

export default Mission;
